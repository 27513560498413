import imgT1 from "../assets/image/learner-1.webp";
import imgT2 from "../assets/image/learner-2.webp";
import imgT3 from "../assets/image/learner-3.webp";
import imgT4 from "../assets/image/learner-4.webp";

export const learnerPageImages = [
  {
    title: "People who care",
    brand: `Human Facilitators`,
    slug: `facilitators`,
    categories: [],
    thumbnail: imgT1,
    text:
      "Even for on-line delivery, you are always in direct touch with the people who are your teachers, tutors, guides or facilitators, as are those who assist them.",
    link: "#",
  },
  {
    title: "People who respond",
    brand: `Feedback`,
    slug: `feedback`,
    categories: [],
    thumbnail: imgT2,
    text:
      "Assignments, queries, reading, tests and more - via audio, video, chat, documents - this conversational system is designed for fast, human responses. No bots!",
    link: "#",
  },
  {
    title: "People who support",
    brand: `Support`,
    slug: `support`,
    categories: [],
    thumbnail: imgT3,
    text:
      "Across your journey, Tuotr personnel are here to support your learning and certification journey. And the interface is the exact same - chat or call. With people.",
    link: "#",
  },
  {
    title: "People who certify",
    brand: `Certification`,
    slug: `certify`,
    categories: [],
    thumbnail: imgT4,
    text:
      "Your interactions on the Tuotr software, as well as pre-qualifications, are validated and approved by Tuotr. Also managed are payments, paperwork and the certification.",
    link: "#",
  },
];
