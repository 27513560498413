import imgT1 from "../assets/image/institutions-1.webp";
import imgT2 from "../assets/image/institutions-2.webp";
import imgT3 from "../assets/image/institutions-3.webp";
import imgT4 from "../assets/image/institutions-4.webp";

export const institutionPageImages = [
  {
    title: "Global Reach",
    brand: `Reach`,
    slug: `reach`,
    categories: [],
    thumbnail: imgT1,
    text:
      "MOOC's and Open courseware have very limited success. Expand your reach and the ability to deliver in-class instructor-led-training across the globe. Break access, language and cost barriers.",
    link: "#",
  },
  {
    title: "Complete Coordination",
    brand: `Coordination`,
    slug: `coordination`,
    categories: [],
    thumbnail: imgT3,
    text:
      "We manage the entire coordination for certification: trainer and learner qualification, study hours, assignments, pre-assessments, payments and more! All managed by real people.",
    link: "#",
  },
  {
    title: "Complete Support",
    brand: `Support`,
    slug: `support`,
    categories: [],
    thumbnail: imgT4,
    text:
      "We manage the biggest challenge operating with small setups at scale - that of the back-end human-powered support systems to handle queries, issues and quality. We don't use AI/ML or 'algorithms'.",
    link: "#",
  },
  {
    title: "Partners in the SDG",
    brand: `Funding`,
    slug: `sdg`,
    categories: [],
    thumbnail: imgT2,
    text:
      "We work with skill dev. bodies, non-profits and philanthropists to help fund Tuotr as well as sponsor training and certification programs worldwide. The challenge is effective delivery, not funds.",
    link: "#",
  },
];
