import imgT1 from "../assets/image/infra-1.webp";
import imgT2 from "../assets/image/infra-2.webp";
import imgT3 from "../assets/image/infra-3.webp";
import imgT4 from "../assets/image/infra-4.webp";

export const infraPageImages = [
    {
    title: "All device, all OS",
    brand: `No Learning Curve`,
    slug: `device_os`,
    categories: [],
    thumbnail: imgT3,
    text:
      "A light-weight solution, for Windows, Mac, Android, IOS and the Web. Training people to use existing solutions takes several weeks. Not with Tuotr. Start using from the first minute, with it's familiar chat interface.",
    link: "#",
  },
  {
    title: "Engaged Learning",
    brand: `Flexible Processes`,
    slug: `engaged`,
    categories: [],
    thumbnail: imgT2,
    text:
      "Ever learnt swimming on-line? Real learning happens in the learners head when supported by human teachers, in the company of peers. Designed not to enforce any process, you are in full control, to use what you need, when you need, and how you need, any capability!",
    link: "#",
  },
  {
    title: "Human Powered",
    brand: `Speak Your Language`,
    slug: `flexible`,
    categories: [],
    thumbnail: imgT4,
    text:
      "No more struggling with IVR, Bots, forms and automation. All our services and co-ordinations are powered by real humans for simple, quick and effective interactions. We truly 'speak' your language.",
    link: "#",
  },
  {
    title: "Supported By Machines",
    brand: `AI/ML Powered`,
    slug: `ai-ml`,
    categories: [],
    thumbnail: imgT1,
    text:
      "Fraud detection, attention levels, geo-location tracking, impersonation, liveliness detection, and many more highly advanced capabilities that support engaged learning and data to support exam qualification are all managed by AI & ML that operate silently in the background.",
    link: "#",
  },
  
  
];
