import imgT1 from "../assets/image/market-1.webp";
import imgT2 from "../assets/image/market-2.webp";
import imgT3 from "../assets/image/market-3.webp";
import imgT4 from "../assets/image/market-4.webp";

export const marketPageImages = [
  {
    title: "Certify and Hire",
    brand: `Self-certify`,
    slug: `employees`,
    categories: [],
    thumbnail: imgT1,
    text:
      "There is no need to setup captive skill development centres. Allow anyone to train and all you need to do is certify them based on your internal criterion.",
    link: "#",
  },
  {
    title: "Vocational Training",
    brand: `Self Employed`,
    slug: `self-employed`,
    categories: [],
    thumbnail: imgT2,
    text:
      "The certified skill gaps in non-formal sectors like electricians, carpenters, plumbers etc. is massive. You can now create on-the-job skilling capacity.",
    link: "#",
  },
  {
    title: "Continuing Education",
    brand: `Professional Dev.`,
    slug: `pdu`,
    categories: [],
    thumbnail: imgT3,
    text:
      "The management of continuing education for professionals is a challenge to monitor and audit. With Tuotr all interactions are recorded, geo-tagged and verified.",
    link: "#",
  },
  {
    title: "Arts & Crafts",
    brand: `Alternate Careers`,
    slug: `crafts`,
    categories: [],
    thumbnail: imgT4,
    text:
      "There are millions engaged in arts like dance, painting and music. And countless others engaged in crafts. There is a huge gap in high-quality, recognized certification.",
    link: "#",
  },
];
