import imgT1 from "../assets/image/homepage-1.webp";
import imgT2 from "../assets/image/homepage-2.webp";
import imgT3 from "../assets/image/homepage-3.webp";
import imgT4 from "../assets/image/homepage-4.webp";

export const homePageImages = [
  {
    title:'The Trainer',
    brand: `Facilitators`,
    slug: `academies`,
    categories: [],
    thumbnail: imgT1,
    text: "Over time the teachers, trainers and facilitators have been systematically underplayed. Yet, they are more relevant than ever before. Human interactions add meaning, motive and personalization to learning.",
  link : "/trainer"
},
  
  {
    title:'The Institution',
    brand: `Certification`,
    slug: `certifier`,
    categories: [],
    thumbnail: imgT2,
    text: "Institutions are similarly under attack, increasingly considered by many to be from the past. Yet, they continue to be serious custodians of structured knowledge, as well as authorities who can certify learners.",
  link : "/institutions"
},
  {
    title: "The Market",
    brand: `Markets & Industry`,
    slug: `market`,
    categories: [],
    thumbnail: imgT3,
    text: "Get job-ready candidates. Help build the workforce the World needs. Support alternate careers in the arts. Lift more people out of poverty. Spend your grants efficiently and effectively!",
  link : "/market"
},
  {
    title: "The Learner",
    brand: `Learners`,
    slug: `learner`,
    categories: [],
    thumbnail: imgT4,
    text: "Leaning at your pace, context and relevant material, which only a human can provide, supported by a highly flexible software system, and genuine industry-recognized certificates is what enables real growth!",
  link : "/learner"
},
];
