import imgT1 from "../assets/image/trainer-1.webp";
import imgT2 from "../assets/image/trainer-2.webp";
import imgT3 from "../assets/image/trainer-3.webp";
import imgT4 from "../assets/image/trainer-4.webp";

export const trainerPageImages = [
  {
    title: "Co-branded Certificates",
    brand: `Branding`,
    slug: `branding`,
    categories: [],
    thumbnail: imgT3,
    text:
      "Deliver globally recognized certifications, no matter how small or big your academy is. Perhaps you are an individual trainer, no matter.",
    link: "#",
  },
  {
    title: "Complete Coordination",
    brand: `Coordination`,
    slug: `coordination`,
    categories: [],
    thumbnail: imgT4,
    text:
      "We manage the entire coordination for certification: qualification, study hours, assignments, pre-assessments, payments and more!",
    link: "#",
  },
  {
    title: "Complete Software",
    brand: `Software`,
    slug: `software`,
    categories: [],
    thumbnail: imgT2,
    text:
      "A simple, chat-based software, for training delivery, discussions, assignments and more. Interactions here are used for qualification.",
    link: "#",
  },
  {
    title: "Complete Flexibility",
    brand: `Control`,
    slug: `flexibility`,
    categories: [],
    thumbnail: imgT1,
    text:
      "You are not forced to onboard students, manage payments, courses and content on the software. Instead do everything the way you are comfortable with, for your complete control and flexibility.",
    link: "#",
  },
];
